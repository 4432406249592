import React from "react"
import Obfuscate from 'react-obfuscate'
import { Row, Col} from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import { AiFillTwitterCircle, AiFillLinkedin } from 'react-icons/ai'

const AdvertContact = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "pexels-lukas-590022.jpg" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Row className='advertblock no-gutters mt-5'>
          <Col lg={true} md={6} className='p-4' id="advertBlockLeft">
              <h4 className='text-center'>Contact Opsmorph</h4>
              <p>Whether you're a startup looking to accelerate development of your cloud platform, a research project looking to introduce machine learning, or an organisation of any size looking to make better use of your data, contact Opsmorph for a free consultation to see how we can help.</p>
              <Obfuscate
                  email="info@opsmorph.com"
                  className="p-2 add-link"
              />
              <a 
                  href='https://twitter.com/opsmorph'
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Link to Opsmorph Twitter"
                  className="p-2"
              >
                  <AiFillTwitterCircle style={{fontSize:'40px', color:'cyan'}} className="pd-4"/>
              </a>
              <a 
                  href='https://www.linkedin.com/company/opsmorph/'
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Link to Opsmorph LinkedIn"
                  className="p-2"
              >
                  <AiFillLinkedin style={{fontSize:'40px', color:'cyan'}} className="pd-4"/>
              </a>
          </Col>
          <Col lg={true} md={6}>
            <Img
              className="h-100 w-100"
              fluid={data.file.childImageSharp.fluid}
              alt="Advert for dashboard design"
            />
          </Col>
      </Row>
      )}
    />
  )
}

export default AdvertContact
