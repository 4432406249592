import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Row, Col } from 'react-bootstrap'

const PostLink = ({ post }) => {
  let featuredImgFluid = post.frontmatter?.featuredImage?.childImageSharp?.fluid
  return (
    <Row className="shadow-sm no-gutters blog-link-row bg-light">
      {post.frontmatter.type === "blog" &&
        <>
          <Col md={4} className="p-2">
            <Img fluid={featuredImgFluid} className="mb-4 h-100 w-100"/>
          </Col>
          <Col md={8} className="p-4">
            <h5>
              <Link to={post.frontmatter.slug}>
                {post.frontmatter.title}
              </Link>
            </h5>
            <p className="mb-0">{post.frontmatter.blurb}</p>
            <p className="blog-link_date mb-0">{post.frontmatter.date}</p>
          </Col>
        </>
      }
      {post.frontmatter.type === "job" &&
        <>
          <Col md={2} className="p-2">
            <Img fluid={featuredImgFluid} className="mb-4 h-100 w-100"/>
          </Col>
          <Col md={10} className="p-4">
            <h5>
              <Link to={post.frontmatter.slug}>
                {post.frontmatter.title}
              </Link>
            </h5>
            <p className="mb-0">{post.frontmatter.blurb}</p>
            <p className="blog-link_date mb-0">{post.frontmatter.date}</p>
          </Col>
        </>
      }
      {post.frontmatter.type === "bio" &&
        <>
          <Col md={2} className="pt-2 pb-2 pl-2">
            <Img fluid={featuredImgFluid}
              style={{height: 120, width: 120, borderRadius: "100%"}}
            />
          </Col>
          <Col md={10} 
            style={{display:"flex",flexDirection: "column", justifyContent:"center"}} className="pb-2 pl-2">
            <h4>
              <Link to={post.frontmatter.slug}>
                {post.frontmatter.name}
              </Link>
            </h4>
            <h6 className="mb-0">{post.frontmatter.title}</h6>
            {/* <p className="blog-link_date mb-0">{post.frontmatter.date}</p> */}
          </Col>
        </>
      }
    </Row>
  )
}

export default PostLink