import React from "react"
import { Container } from "react-bootstrap"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import AdvertContact from "../components/advert"
import { graphql } from "gatsby"
import PostLink from "../components/post-link"

const AboutPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {

  let people = {}
  edges.filter(edge => edge.node.frontmatter.type === "bio")
    .forEach(p => {people[p.node.frontmatter.name] = p})
  const TeamLinks =  [
      'Graham Hesketh',
      'Giuseppe Maio',
      'Jojeena Kolath', 
      'Aliai Eusebi',
      'Kyrylo Khazanzheiev'
    ]
    .map(name => people[name])
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return (
  <>
    <Header />
    <SEO title="About Page" />
    <Container className="mt-4">
      <h1>About Opsmorph</h1>
      <p>Opsmorph is a London based data science and cloud solutions research and consultancy company formed in 2020. Its mission is to work in co-design with organisations of all sizes to accelerate their development of innovative applications using state-of-the-art cloud technology, web science, machine learning and data visualisation. We are motivated to empower all of society to benefit from and contribute to the development of new technology and we are committed to tackling the biggest of societies challenges together.</p>
      <h2>Team</h2>
      <div>{TeamLinks}</div>
      <AdvertContact />
    </Container>
    <Footer />
  </>
)}

export default AboutPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            name
            title
            type
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`